import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { sha1 } from 'js-sha1';

import { LoaderService } from '@watchguard/wg-loader';

import { SetPassword, ChangePassword } from './password.model';
import { BASE_PASSWORD_URL } from '../config/app-settings';

@Injectable()
export class PasswordService {

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService
  ) { }

  setPassword(password: SetPassword): Observable<any> {
    this.loaderService.showLoading();
    return this
      .http.patch(`${this.buildURL(password.accountId)}/${password.userId}/password`, password).pipe(
        finalize(() => this.loaderService.hideLoading())
      );
  }

  forgetPassword(accountId: string, login: string): Observable<any> {
    this.loaderService.showLoading();
    return this
      .http.post(`${this.buildURL(accountId)}/password/email`, { login }).pipe(
        finalize(() => this.loaderService.hideLoading())
      );
  }

  changePassword(changePassword: ChangePassword, accountId: string): Observable<any> {
    this.loaderService.showLoading();
    const entireHash = sha1(changePassword.newPassword);
    changePassword.initialHash = entireHash.substring(0,5);
    changePassword.finalHash = entireHash.substring(5);
    return this
      .http.post(`${this.buildURL(accountId)}/password`, changePassword).pipe(
        finalize(() => this.loaderService.hideLoading())
      );
  }

  private buildURL(accountId: string): string {
    return `${BASE_PASSWORD_URL}/v1/accounts/${accountId}/users`;
  }

}

