export class SetPassword {
  userId: number;
  accountId: string;
  emailKey: string;
  password: string;
  confirmPassword: string;
  finalHash: string;
  initialHash: string;
}

export class ChangePassword {
  newPassword: string;
  password: string;
  confirmPassword: string;
  login: string;
  finalHash: string;
  initialHash: string;
}
